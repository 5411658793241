import * as React from "react";
import PropTypes from "prop-types";
import { useSiteMetadata } from "../hooks/use-site-metadata";

function Seo({ description, title, image, author, siteUrl, keywords, structuredData }) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl: defaultSiteUrl,
    author: defaultAuthor,
    imageUrl: defaultImageUrl,
  } = useSiteMetadata();

  const metaDescription = description || defaultDescription;
  const metaTitle = title || defaultTitle;
  const metaImage = image || defaultImageUrl;
  const metaAuthor = author || defaultAuthor;
  const metaUrl = siteUrl || defaultSiteUrl;

  return (
    <>
  
        <title>{metaTitle}</title>
        <link rel="canonical" href={metaUrl} />
        <meta httpEquiv="language" content="en-US"></meta>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={keywords} /> {/* Adding keywords */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={metaAuthor} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
        {/* {structuredData && (
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        )} */}

    </>
  );
}

Seo.defaultProps = {
  description: ``,
  image: ``,
  keywords: ``,
};

Seo.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string, // PropType for keywords
  // structuredData: PropTypes.object,
};

export default Seo;


